<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Sale') }}</b-card-title>
      </b-card-header>

      <b-card-body>
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm"
          >

            <!-- Header -->
            <b-row>

              <!-- Field: Client -->
              <b-col
                cols="6"
                md="3"
              >

                <validation-provider
                  #default="validationContext"
                  name="client"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Client')"
                    label-for="client"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="saleData.client_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="client"
                      @option:selected="addClient"
                    >
                      <template #selected-option="{ label }">
                        <div>
                          {{ truncateText(label) }}
                        </div>
                      </template>

                      <template #option="{ label, value }">
                        <div
                          v-if="value === 0"
                          class="text-success"
                        >
                          + Add New ...
                        </div>
                      </template>

                    </v-select>

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <!-- Field: Total Amount -->
              <b-col
                cols="6"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="total-amount"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Total Amount')"
                    label-for="total-amount"
                  >
                    <b-form-input
                      id="total-amount"
                      v-model="saleData.total_amount"
                      class="plain border-light"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                      readonly
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Discount -->
              <b-col
                cols="6"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="discount"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Discount')"
                    label-for="discount"
                  >
                    <b-form-input
                      id="discount"
                      v-model="saleData.discount"
                      :state="getValidationState(validationContext)"
                      type="number"
                      trim
                      placeholder=""
                      @focus="$event.target.select()"
                      @input="calTotalAmount()"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Net Amount -->
              <b-col
                cols="6"
                md="3"
              >
                <b-form-group
                  :label="$t('Net')"
                  label-for="net-amount"
                  label-class="font-weight-bold"
                >
                  <b-form-input
                    id="net-amount"
                    v-model="saleData.net_amount"
                    trim
                    placeholder=""
                    class="plain font-weight-bold border-light"
                    readonly
                  />

                </b-form-group>
              </b-col>

            </b-row>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <section
              class="invoice-preview-wrapper"
            >
              <b-row class="mb-1">
                <!-- Field: Item -->
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('Select Item')"
                    label-for="item"
                  >
                    <v-select
                      v-model="itemId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="itemOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :searchable="true"
                      input-id="item"
                      @close="nextToQuantity"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: Quantity -->
                <b-col
                  md="4"
                >
                  <b-form-group
                    :label="$t('Quantity')"
                    label-for="quantity"
                  >
                    <b-input-group>
                      <b-form-input
                        id="quantity"
                        ref="quantity"
                        v-model="itemQuantity"
                        type="number"
                        trim
                        placeholder=""
                        @keyup.enter="quantityEnter"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="addNewItem"
                        >
                          {{ $t('Add') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                  </b-form-group>

                </b-col>
              </b-row>

              <div
                ref="formitem"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in saleData.sale_items"
                  :key="index"
                  ref="rowitem"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">

                      <b-row class="flex-grow-1 p-1">

                        <!-- Item Name -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline">{{ $t('Item') }}</label>
                          <p class="mb-1">
                            {{ item.item_id }} - {{ item.item_name }}
                          </p>
                        </b-col>

                        <!-- Price -->
                        <b-col
                          cols="6"
                          lg="3"
                          sm="4"
                        >
                          <label class="d-inline">{{ $t('Price') }}</label>
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="mb-1"
                            @input="calItemAmount(item.item_id, item.price, item.quantity)"
                          />
                        </b-col>

                        <!-- Quantity -->
                        <b-col
                          cols="6"
                          lg="2"
                          sm="4"
                        >
                          <label class="d-inline">{{ $t('Quantity') }}</label>
                          <b-form-input
                            v-model="item.quantity"
                            type="number"
                            class="mb-1"
                            @input="calItemAmount(item.item_id, item.price, item.quantity)"
                          />
                        </b-col>

                        <!-- Item Amount -->
                        <b-col
                          cols="12"
                          lg="2"
                          sm="4"
                        >
                          <label class="d-inline">{{ $t('Amount') }}</label>
                          <b-form-input
                            v-model="item.amount"
                            type="text"
                            class="mb-1 plain border-light"
                            readonly
                          />
                        </b-col>
                      </b-row>

                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />

                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

            </section>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Form Actions -->
            <div
              class="d-flex flex-row mt-2"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="gradient-secondary"
                class="mr-1"
                :class="{'col-sm-6': isMobile}"
                type="button"
                pill
                @click="close"
              >
                {{ $t('Close') }}

              </b-button>
              <b-button
                v-if="saleData.sale_items.length > 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                :class="{'col-sm-6': isMobile}"
                type="button"
                pill
                @click="onSubmit"
              >
                {{ $t('Save') }}
              </b-button>
            </div>

          </b-form>

        </validation-observer>

      </b-card-body>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import OfflineDB from '@/libs/offline-db'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import storeModule from '../../../common/storeModule'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isMobile: false,
      trHeightReceipt: null,
      required,
      itemOptions: [],
      clientOptions: [],
      saleRegionOptions: [],
      itemId: null,
      itemQuantity: null,
      offlineDB: new OfflineDB(),
      isDisabled: true,
    }
  },
  watch: {

  },
  mounted() {
    this.updateScreenWidth()
    window.addEventListener('resize', this.updateScreenWidth)
    this.initTrHeight()
    this.loadClients()
    this.loadItems()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.clientOptions.unshift({ label: '+ Add New', value: 0 })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    updateScreenWidth() {
      this.isMobile = window.innerWidth <= 768 || window.innerWidth <= 1024 // 768px is a common breakpoint for 'sm' screens
    },
    addClient(option) {
      if (option.value === 0) {
        this.$router.push({ name: 'pos-client-new', query: { ref: 'pos' } })
      }
    },
    truncateText(text) {
      const maxLength = this.isMobile ? 10 : 100 // Set your desired max length
      return text.length > maxLength ? `${text.substring(0, maxLength)}..` : text
    },
    nextToQuantity() {
      document.getElementById('quantity').focus()
    },
    quantityEnter() {
      this.addNewItem()
    },
    loadClients() {
      store
        .dispatch('common/fetchPosClients')
        .then(response => {
          const { clients } = response.data.data
          for (let i = 0; i < clients.length; i += 1) {
            this.clientOptions.push({ label: clients[i].name, value: clients[i].id })
          }
          const cid = this.$router.currentRoute.query.customer ?? clients[0].id
          this.saleData.client_id = parseInt(cid, 10)
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadItems() {
      store
        .dispatch('common/fetchPosItems')
        .then(response => {
          const { items } = response.data.data
          this.offlineDB.items.clear()
          this.offlineDB.items.bulkAdd(items)
          // const someFriends = await this.offlineDB.items.where({ id: 2 }).first()
          // console.log(someFriends)
          for (let i = 0; i < items.length; i += 1) {
            this.itemOptions.push({ label: items[i].name, value: items[i].id })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    close() {
      this.$router.push({ name: 'pos-sale-list' })
    },
    async getItemPrice(itemId, quantity) {
      const item = await this.offlineDB.items.where({ id: itemId }).first()
      let sprice = Number(item.sale_price)
      const { pricing } = item
      if (pricing.length !== 0) {
        for (let i = 0; i < pricing.length; i += 1) {
          const isBetweenMinMax = (quantity >= pricing[i].minimum_quantity && quantity <= pricing[i].maximum_quantity)
          const isGreaterThanMin = (quantity >= pricing[i].minimum_quantity)
          if (isBetweenMinMax || isGreaterThanMin) {
            sprice = pricing[i].price
          }
        }
      }
      return sprice
    },
    async addNewItem() {
      if (this.itemId !== null && this.itemQuantity !== null) {
        this.$refs.formitem.style.overflow = 'hidden'
        const selectedItem = await this.offlineDB.items.where({ id: this.itemId }).first()

        if (this.saleData.sale_items.some(obj => obj.item_id === this.itemId)) {
          this.$swal('Warning', 'Item already exists', 'warning')
          return
        }

        const sqty = Number(this.itemQuantity)
        let sprice = Number(selectedItem.sale_price)

        const { pricing } = selectedItem
        if (pricing.length !== 0) {
          for (let i = 0; i < pricing.length; i += 1) {
            const isBetweenMinMax = (sqty >= pricing[i].minimum_quantity && sqty <= pricing[i].maximum_quantity)
            const isGreaterThanMin = (sqty >= pricing[i].minimum_quantity)
            if (isBetweenMinMax || isGreaterThanMin) {
              sprice = pricing[i].price
              break
            }
          }
        }

        const selectedName = selectedItem.name.split(' - ')
        const itemName = `${selectedName[0]} ${selectedName[1]}`

        this.itemFormBlankItem.item_name = itemName
        this.itemFormBlankItem.item_id = selectedItem.id
        this.itemFormBlankItem.price = sprice
        this.itemFormBlankItem.quantity = sqty
        this.itemFormBlankItem.unit = selectedItem.unit.name
        this.itemFormBlankItem.amount = sprice * sqty

        this.saleData.sale_items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.itemId = null
        this.itemQuantity = null

        this.calTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rowitem[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formitem.style.overflow = null
          }, 350)
        })
        document.getElementById('item').focus()
      }
    },
    removeItem(index) {
      this.saleData.sale_items.splice(index, 1)
      this.calTotalAmount()
      this.trTrimHeight(this.$refs.rowitem[0].offsetHeight)
    },
    async calItemAmount(itemId, price, quantity) {
      const selectedItem = await this.offlineDB.items.where({ id: itemId }).first()
      let sprice = selectedItem.sale_price
      const { pricing } = selectedItem
      if (pricing.length !== 0) {
        for (let i = 0; i < pricing.length; i += 1) {
          const isBetweenMinMax = (quantity >= pricing[i].minimum_quantity && quantity <= pricing[i].maximum_quantity)
          const isGreaterThanMin = (quantity >= pricing[i].minimum_quantity)
          if (isBetweenMinMax || isGreaterThanMin) {
            sprice = pricing[i].price
            break
          }
        }
      }

      const index = this.saleData.sale_items.findIndex((obj => obj.item_id === itemId))

      this.saleData.sale_items[index].price = sprice

      const amount = Number(sprice) * Number(quantity)
      this.saleData.sale_items[index].amount = amount
      this.calTotalAmount()
    },
    calTotalAmount() {
      this.saleData.total_amount = this.saleData.sale_items.reduce((a, b) => +a + +b.amount, 0)
      this.saleData.net_amount = this.saleData.total_amount - this.saleData.discount
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formitem.scrollHeight)
      })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    /** request data format
    {
      "sale_date" : "2021-03-21",
      "client_id" : 3,
      "total_amount" : 1000,
      "total_receipt" : 1000,
      "discount" : 0,
      "reference" : "457",
      "remark" : "none",
      "sale_region_id" : 1,
      "status" : 3,
      "sale_items" : [
          {
            "item_id" : 2,
            "quantity" : 1,
            "price" : 500,
            "amount" : 500
          },
      ],
      "sale_receipts" : [
          {
              "receipt_date" : "2021-03-21",
              "amount" : 1000,
              "reference" : "cash"
          }
      ]
    }
    */

    const itemFormBlankItem = {
      item_name: '',
      item_id: null,
      price: 0,
      quantity: 0,
      amount: 0,
    }

    const toast = useToast()
    const current = new Date()
    const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
    const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
    const today = `${current.getFullYear()}-${month}-${date}`

    // const minDate = current // minimum date that user can choose in new sale invoice
    const maxDate = (new Date(current.getFullYear(), current.getMonth() + 1, 0)) // maximum date that user can choose in new sale invoice

    const router = context.root.$router

    const saleData = ref({
      sale_date: today,
      client_id: null,
      total_amount: 0,
      discount: 0,
      net_amount: 0,
      total_receipt: 0,
      employee_name: '',
      reference: '',
      remark: '',
      status: 3, // default status Paid for POS
      sale_region_id: null, // default sale region for POS
      sale_items: [],
      sale_receipts: [],
    })

    const onSubmit = () => {
      // receipt and amount is same for POS, cash sale
      saleData.value.total_receipt = saleData.value.net_amount

      saleData.value.sale_receipts = [
        {
          receipt_date: today,
          reference: 'Cash Pay',
          amount: saleData.value.net_amount,
        },
      ]

      const data = JSON.parse(JSON.stringify(saleData.value))
      store.dispatch('sale/add', data)
        .then(response => {
          if (response.status === 201) {
            router.push({ name: 'pos-sale-view', params: { id: response.data.data.id } })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Warning',
                text: 'Please enter completed information',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      saleData,
      itemFormBlankItem,
      today,
      // minDate,
      maxDate,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
[dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator {
  background-color: #ffffff;
}

</style>
